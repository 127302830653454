import { configureStore } from '@reduxjs/toolkit'
import rootSaga from 'sagas/rootSaga'
import { sagaMiddleware } from './middleware'
import rootReducer from './reducers/rootReducer'

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
