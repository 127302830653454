import { AxiosResponse } from 'axios'
import { INotification, IPaginate } from 'models'
import moment from 'moment'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  notificationsFetchRequested,
  setNotifications
} from 'store/reducers/notificationsReducer'
import api from 'utils/api'

function* fetchNotifications(action: { type: string; pageNumber: number }) {
  const params = {
    'page-size': 10,
    'page-number': action.pageNumber
  }
  const response: AxiosResponse<IPaginate<INotification>> = yield call(() =>
    api.get('/notices/news', {
      params
    })
  )

  const result = {
    content: response.data.content.map((el: INotification) => ({
      ...el,
      createdAt: moment(el.createdAt).format('yyyy.MM.DD HH:mm')
    })),
    totalElements: response.data.totalElements,
    totalPages: response.data.totalPages,
    numberOfElements: response.data.numberOfElements
  }
  yield put(setNotifications(result))
}

export default function* notificationsSaga() {
  yield takeEvery(notificationsFetchRequested.type, fetchNotifications)
}
