import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotification, IPaginate } from 'models'
import { RootState } from 'store'

type NotificationsStateType = IPaginate<INotification> | null

const initialState = null as NotificationsStateType

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (
      state,
      action: PayloadAction<IPaginate<INotification>>
    ) => {
      state = { ...action.payload }
      return state
    }
  }
})

export const { setNotifications } = notificationsSlice.actions

export const notificationsFetchRequested = {
  type: 'notifications/notificationsFetchRequested'
}

export const selectNotifications = (state: RootState) => state.notifications

export default notificationsSlice.reducer
