const routes = {
  root: '/',
  auth: '/auth',
  users: '/users',
  userPreview: '/users/:id',
  userEdit: '/users/:id/edit',
  providerCards: '/provider-cards',
  providerCard: '/provider-cards/:id',
  providerCardEdit: '/provider-cards/:id/edit',
  providerCardCreate: '/provider-card-create',
  moderationCards: '/moderation-cards',
  moderationCard: '/moderation-cards/:id',
  notifications: '/notifications',
  notificationPreview: '/notifications/:id',
  notificationCreate: '/notification-create',
  updateDB: '/update-db'
}

export default routes
