import {
  ICardTag,
  IProviderCardFull,
  IProviderCardShort,
  IUserEdit,
  IUserFull
} from 'models'
import moment from 'moment'
import { ICardTagResponse } from './utilTypes'

export type IProviderCardShortOld = {
  id: number
  data: {
    name: string
    baseTypes: string[]
    ownersPhone: string
  }
}

export type IProviderCardFullOld = {
  id: number
  data: {
    image: string
    xCoordinate: number
    yCoordinate: number
    baseTypes: []
    workTime: {
      start: string
      end: string
    }
    address: string
    addedByOwner: boolean
    hideOwnerPhone: boolean
    wheelSizes: []
    priceList: []
    name: string
    carTypes: []
    facilities: []
    tags: []
    ownersPhone: string
    comment: string
    bookingName: string
    bookingPhone: string
    emailAddress: string
  }
}

export const mapResponseToProviderCardsType = (
  oldArr: IProviderCardShortOld[]
) => {
  const newArr: IProviderCardShort[] = []
  oldArr.forEach((oldElem) => {
    newArr.push({
      id: oldElem.id,
      name: oldElem.data?.name,
      serviceTypes: oldElem.data?.baseTypes,
      ownersPhone: oldElem.data?.ownersPhone
    })
  })
  return newArr
}

export const mapResponseToProviderCardType = (oldObj: IProviderCardFullOld) => {
  const result: IProviderCardFull = {
    id: oldObj.id,
    name: oldObj.data.name,
    address: oldObj.data.address,
    image: oldObj.data.image,
    coords: oldObj.data.xCoordinate + ', ' + oldObj.data.yCoordinate,
    ownersPhone: oldObj.data.ownersPhone,
    hideOwnerPhone: oldObj.data.hideOwnerPhone,
    facilities: oldObj.data.facilities,
    baseTypes: oldObj.data.baseTypes,
    carTypes: oldObj.data.carTypes.map((ct: any) => ct.id),
    priceList: oldObj.data.priceList,
    tags: oldObj.data.tags,
    addedByOwner: oldObj.data.addedByOwner,
    comment: oldObj.data.comment,
    bookingName: oldObj.data.bookingName,
    bookingPhone: oldObj.data.bookingPhone,
    emailAddress: oldObj.data.emailAddress,
    wheelSizes: oldObj.data.wheelSizes.map((ws: any) => ws.size),
    workTime: {
      start: moment(oldObj.data.workTime.start, 'HH:mm:ss'),
      end: moment(oldObj.data.workTime.end, 'HH:mm:ss')
    }
  }
  return result
}

export const mapResponseToUser = (oldUser: IUserFull | null) => {
  if (oldUser) {
    const newUser: IUserEdit = {
      id: oldUser.id,
      name: oldUser.name,
      city: oldUser.city,
      wheelSize: oldUser.wheelSize?.size,
      image: oldUser.image,
      owner: oldUser.owner,
      carTypeId: oldUser.carType?.id
    }
    return newUser
  }
}

export const cardTagsMapper = (old: ICardTagResponse[]): ICardTag[] => {
  const cardTagsRender: ICardTag[] = old
    .map((tag) => ({
      type: tag.type,
      tags: []
    }))
    .filter(
      (category, idx, array) =>
        idx === array.findIndex((el) => el.type === category.type)
    )

  cardTagsRender.forEach((category) => {
    old.forEach((tag) => {
      if (tag.type === category.type) {
        category.tags.push({ id: tag.id, name: tag.name, type: tag.type })
      }
    })
  })
  return cardTagsRender
}
