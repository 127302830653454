import axios, { AxiosRequestConfig } from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT
})

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token')
  const newHeaders: { Authorization?: string } = {}
  if (token) {
    newHeaders.Authorization = `Bearer ${token}`
  }
  config.headers = newHeaders

  return config
})

export default api
