import { AxiosResponse } from 'axios'
import { INotification, IProviderCardFull } from 'models'
import moment from 'moment'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  notificationFetchRequested,
  setNotification
} from 'store/reducers/notificationReducer'
import {
  providerCardFetchRequested,
  setProviderCard
} from 'store/reducers/providerCardReducer'
import api from 'utils/api'
import {
  IProviderCardFullOld,
  mapResponseToProviderCardType
} from 'utils/mappers'

function* fetchProviderCard(action: { type: string; id: number }) {
  const response: AxiosResponse<IProviderCardFullOld> = yield call(() =>
    api.get(`/map-points/${action.id}`)
  )

  yield put(setProviderCard(mapResponseToProviderCardType(response.data)))
}

export default function* providerCardSaga() {
  yield takeEvery(providerCardFetchRequested.type, fetchProviderCard)
}
