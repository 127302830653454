import { Layout } from 'antd'
import { ReactNode } from 'react'
import Sidebar from 'layout/Sidebar'

const { Content } = Layout

type Props = {
  children: ReactNode
}

const DefaultLayout: React.FC<Props> = ({ children }: Props) => (
  <Layout style={{ height: '100%' }}>
    <Sidebar />
    <Layout style={{ marginLeft: '340px', backgroundColor: 'white' }}>
      <Content
        style={{
          overflow: 'initial',
          paddingLeft: 56,
          paddingTop: 70,
          position: 'relative'
        }}
      >
        {children}
      </Content>
    </Layout>
  </Layout>
)

export default DefaultLayout
