import {
  Layout,
  Image,
  Typography,
  Space,
  Menu,
  Button,
  Badge,
  Popconfirm
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Logo from 'assets/images/logo.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks'
import { memo, useEffect, useState } from 'react'
import routes from 'routes'
import { selectProfile, setProfile } from 'store/reducers/profileReducer'
import api from 'utils/api'
import { isAdmin } from 'utils/functions'

const { Sider } = Layout
const { Title, Text } = Typography

const SiderHeader: React.FC = memo(() => {
  const profile = useAppSelector(selectProfile)

  return (
    <>
      <Space
        size={'middle'}
        style={{
          paddingLeft: 24,
          marginBottom: 40
        }}
      >
        <Image
          width={48}
          height={48}
          src={Logo}
          preview={false}
        />
        <Space
          direction="vertical"
          size={0}
        >
          {profile && (
            <>
              <Title
                level={4}
                style={{
                  fontSize: '16px',
                  color: 'white',
                  marginBottom: 0
                }}
              >
                {profile && profile.login ? profile.login : 'Admin'}
              </Title>
              <Text style={{ fontWeight: 300, color: '#8B90A9' }}>
                {profile.role === 'ADMIN' ? 'Администратор' : 'Модератор'}
              </Text>
            </>
          )}
        </Space>
      </Space>
      <Link
        to={routes.providerCardCreate}
        style={{ display: 'block', marginLeft: 24 }}
      >
        <Button
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          style={{
            backgroundColor: '#3751FF',
            border: 'none',
            width: 292,
            height: 48,
            borderRadius: 8,
            marginBottom: 30,
            fontSize: 14
          }}
        >
          Создать карточку
        </Button>
      </Link>
      <div
        style={{
          marginBottom: 30,
          marginLeft: 24,
          width: 292,
          height: 1,
          backgroundColor: '#dde2ff'
        }}
      />
    </>
  )
})

const SiderMenu = () => {
  const location = useLocation()
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const [count, setCount] = useState(0)
  const profile = useAppSelector(selectProfile)

  useEffect(() => {
    api
      .get('/map-points', { params: { approved: false } })
      .then((res) => setCount(res.data.totalElements))
  }, [location])

  const handleLogout = () => {
    localStorage.removeItem('token')
    navigation('/auth')
    dispatch({ type: setProfile, action: null })
  }

  return (
    <StyledMenu
      mode="inline"
      defaultSelectedKeys={[location.pathname.slice(1)]}
      selectedKeys={[location.pathname.slice(1)]}
      style={{
        marginBottom: 32,
        borderRight: 0,
        backgroundColor: '#363740'
      }}
    >
      {isAdmin(profile) && (
        <Menu.Item
          key="users"
          style={{ backgroundColor: '#363740' }}
        >
          <Link to="/users">
            <Text
              style={{
                fontSize: '16px',
                color: '#A4A6B3'
              }}
            >
              Пользователи
            </Text>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item
        key="provider-cards"
        style={{
          backgroundColor: '#363740',
          color: '#A4A6B3'
        }}
      >
        <Link to="/provider-cards">
          <Text
            style={{
              fontSize: '16px',
              color: '#A4A6B3'
            }}
          >
            Карточки поставщиков
          </Text>
        </Link>
      </Menu.Item>
      <Menu.Item
        key="moderation-cards"
        style={{
          backgroundColor: '#363740',
          color: '#A4A6B3'
        }}
      >
        <Link to="/moderation-cards">
          <Space>
            <Text
              style={{
                fontSize: '16px',
                color: '#A4A6B3'
              }}
            >
              На модерации
            </Text>
            {!!count && count > 0 && (
              <Badge
                count={count}
                style={{
                  backgroundColor: '#3751FF',
                  boxShadow: 'none',
                  color: '#DDE2FF'
                }}
              />
            )}
          </Space>
        </Link>
      </Menu.Item>
      {isAdmin(profile) && (
        <Menu.Item
          key="notifications"
          style={{
            backgroundColor: '#363740',
            color: '#A4A6B3'
          }}
        >
          <Link to="/notifications">
            <Text
              style={{
                marginBottom: 20,
                fontSize: '16px',
                color: '#A4A6B3'
              }}
            >
              Уведомления
            </Text>
          </Link>
        </Menu.Item>
      )}
      <div
        style={{
          marginTop: 30,
          marginBottom: 20,
          marginLeft: 24,
          width: 292,
          height: 0.5,
          backgroundColor: '#dde2ff'
        }}
      />
      {isAdmin(profile) && (
        <Menu.Item
          key="update-db"
          style={{
            backgroundColor: '#363740',
            color: '#A4A6B3'
          }}
        >
          <Link to="/update-db">
            <Text
              style={{
                marginBottom: 20,
                fontSize: '16px',
                color: '#A4A6B3'
              }}
            >
              Обновление БД
            </Text>
          </Link>
        </Menu.Item>
      )}
      <div
        style={{
          backgroundColor: '#363740',
          color: '#A4A6B3'
        }}
      >
        <a
          href="https://google.com"
          rel="noreferrer"
          target={'_blank'}
          style={{ display: 'block' }}
        >
          <Space
            direction="horizontal"
            size={0}
            align="center"
            style={{
              width: 341,
              height: 56
            }}
          >
            <Text
              style={{
                fontSize: '16px',
                color: '#A4A6B3',
                paddingLeft: 24
              }}
            >
              Как пользоваться?
            </Text>
          </Space>
        </a>
      </div>
      <div
        style={{
          backgroundColor: '#363740',
          color: '#A4A6B3'
        }}
      >
        <Popconfirm
          title="Вы уверены, что хотите выйти?"
          okText="Да"
          cancelText="Отмена"
          onConfirm={handleLogout}
        >
          <div style={{ display: 'block', cursor: 'pointer' }}>
            <Space
              direction="horizontal"
              size={0}
              align="center"
              style={{
                width: 341,
                height: 56
              }}
            >
              <Text
                style={{
                  fontSize: '16px',
                  color: '#A4A6B3',
                  paddingLeft: 24
                }}
              >
                Выход
              </Text>
            </Space>
          </div>
        </Popconfirm>
      </div>
    </StyledMenu>
  )
}

const Sidebar: React.FC = () => (
  <>
    <Sider
      width={340}
      style={{
        backgroundColor: '#363740',
        overflow: 'hidden',
        height: '100vh',
        position: 'fixed',
        paddingTop: 32,
        left: 0,
        top: 0,
        bottom: 0
      }}
    >
      <SiderHeader />
      <SiderMenu />
    </Sider>
  </>
)

const StyledMenu = styled(Menu)`
  .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0 !important;
    height: 56px;
  }

  .ant-menu-item-selected {
    background-color: rgba(159, 162, 180, 0.08) !important;

    .ant-typography {
      color: #dde2ff !important;
    }

    ::after {
      left: 0px;
      right: initial !important;
      border-right: 3px solid #dde2ff;
    }
  }
`

export default Sidebar
