import { createGlobalStyle } from 'styled-components'

import interBlack from 'assets/fonts/Inter/Inter-Black.ttf'
import interExtraBold from 'assets/fonts/Inter/Inter-ExtraBold.ttf'
import interBold from 'assets/fonts/Inter/Inter-Bold.ttf'
import interSemiBold from 'assets/fonts/Inter/Inter-SemiBold.ttf'
import interMedium from 'assets/fonts/Inter/Inter-Medium.ttf'
import interRegular from 'assets/fonts/Inter/Inter-Regular.ttf'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src:
      url(${interBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src:
      url(${interExtraBold}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src:
      url(${interBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src:
      url(${interSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src:
      url(${interMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }  

  @font-face {
    font-family: 'Inter';
    src:
      url(${interRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }  

  * {
    box-sizing: border-box;
    outline: none;
    border: none;
  }

  a {
    text-decoration: none;
  }
  
  body {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .ant-table {
    padding-right: 64px;

    &-thead {
      tr {
        th {
          background-color: white !important;
          color: #A0A0A0
        }
      }
    }
    &-tbody {
      cursor: pointer;

      & >:nth-child(2n) {
        background-color: #F2F4FA;
      }
    }

    &-cell-row-hover {
      background-color: #EFF2FF !important;
    }
  }

  .ant-input {
    height: 40px !important;

    &-affix-wrapper {
      height: 40px !important;
    
      input {
        height: 28px !important;
      }
    }
  }

  .ant-picker {
    height: 40px;
  }

  .ant-form-item {
    margin-bottom: 0;

    &-explain {
      position: absolute;
      font-size: 12px;
      bottom: -24px;
    }
  }

  .ant-modal {
    top: 250px;
  }

  .ant-select {
    height: 40px;

    &-selector {
      height: 40px !important;
    }

    &-selection-item {
      display: flex;
      align-items: center;
    }
  }

  .ant-row {
    margin-right: 0 !important;
  }

  .google-maps-not-draggable .gmnoprint {
    display: none;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

.quill {
  .ql {
    &-toolbar {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &-container {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
} 
`

export default GlobalStyles
