import { AxiosResponse } from 'axios'
import { IPaginate, IProviderCardsFilter } from 'models'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  providerCardsFetchRequested,
  setProviderCards
} from 'store/reducers/providerCardsReducer'
import api from 'utils/api'
import {
  IProviderCardShortOld,
  mapResponseToProviderCardsType
} from 'utils/mappers'

function* fetchProviderCards(action: {
  type: string
  filter: IProviderCardsFilter
  pageNumber: number
  approved: boolean
}) {
  const newParams: {
    'page-number': number
    'page-size': number
    'base-type'?: string
    'car-type-name'?: string
    'map-point-name'?: string
    'service-type-name'?: string
    approved: boolean
  } = {
    'page-number': action.pageNumber,
    'page-size': 10,
    approved: action.approved
  }
  if (action.filter?.baseType) {
    newParams['base-type'] = action.filter.baseType
  }
  if (action.filter?.carTypeName) {
    newParams['car-type-name'] = action.filter.carTypeName
  }
  if (action.filter?.mapPointName) {
    newParams['map-point-name'] = action.filter.mapPointName
  }
  if (action.filter?.serviceType) {
    newParams['service-type-name'] = action.filter.serviceType
  }

  const response: AxiosResponse<IPaginate<IProviderCardShortOld>> = yield call(
    () => api.get('/map-points', { params: newParams })
  )
  const mappedResponse = {
    content: mapResponseToProviderCardsType(response.data.content),
    totalElements: response.data.totalElements,
    totalPages: response.data.totalPages,
    numberOfElements: response.data.numberOfElements
  }
  yield put(setProviderCards(mappedResponse))
}

export default function* providerCardsSaga() {
  yield takeEvery(providerCardsFetchRequested.type, fetchProviderCards)
}
