import { AxiosError, AxiosResponse } from 'axios'
import { IUserFull } from 'models'
import { NavigateFunction } from 'react-router-dom'
import { call, put, takeEvery } from 'redux-saga/effects'
import { setUser, userFetchRequested } from 'store/reducers/userReducer'
import api from 'utils/api'

function* fetchUser(action: {
  type: string
  id: number
  navigation: NavigateFunction
}) {
  const response: AxiosResponse<IUserFull> = yield call(() =>
    api.get(`/users/data/${action.id}`).catch((e: AxiosError) => {
      if ((e.toJSON() as { status: number }).status === 401) {
        action.navigation('/auth')
      }
    })
  )
  if (response) {
    yield put(setUser(response.data))
  }
}

export default function* userSaga() {
  yield takeEvery(userFetchRequested.type, fetchUser)
}
