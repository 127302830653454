import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import routes from 'routes'
import DefaultLayout from 'layout/DefaultLayout'
import { useAppSelector } from 'hooks'
import { selectProfile } from 'store/reducers/profileReducer'
import { lazy, Suspense } from 'react'
import AppPreloader from './AppPreloader'
import { isAdmin, isModer } from 'utils/functions'

const CreateCardPage = lazy(() => import('views/ProviderCardCreatePage'))
const AuthPage = lazy(() => import('views/AuthPage'))
const ProviderCardsPage = lazy(() => import('views/ProviderCardsPage'))
const NotificationCreatePage = lazy(
  () => import('views/NotificationCreatePage')
)
const ProviderCardEditPage = lazy(() => import('views/ProviderCardEditPage'))
const ModerationCardPage = lazy(() => import('views/ModerationCardPage'))
const ModerationCardsPage = lazy(() => import('views/ModerationCardsPage'))
const NotificationPreviewPage = lazy(
  () => import('views/NotificationPreviewPage')
)
const NotificationsPage = lazy(() => import('views/NotificationsPage'))
const UserEditPage = lazy(() => import('views/UserEditPage'))
const UserPreviewPage = lazy(() => import('views/UserPreviewPage'))
const UsersPage = lazy(() => import('views/UsersPage'))
const UpdateDBPage = lazy(() => import('views/UpdateDBPage'))

const AppRouter: React.FC = () => {
  const profile = useAppSelector(selectProfile)

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={routes.auth}
          element={
            <Suspense fallback={<AppPreloader />}>
              <AuthPage />
            </Suspense>
          }
        />
        <Route
          path={routes.root}
          element={
            <DefaultLayout>
              {isAdmin(profile) && <Navigate to="/users" />}
              {isModer(profile) && <Navigate to="/provider-cards" />}
              {!profile && <Navigate to="/provider-cards" />}
            </DefaultLayout>
          }
        />
        <Route
          path={routes.users}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <UsersPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.userPreview}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <UserPreviewPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.userEdit}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <UserEditPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.providerCards}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <ProviderCardsPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.providerCardEdit}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <ProviderCardEditPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.moderationCards}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <ModerationCardsPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.moderationCard}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <ModerationCardPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.notifications}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <NotificationsPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.notificationPreview}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <NotificationPreviewPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.notificationCreate}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <NotificationCreatePage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.providerCardCreate}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <CreateCardPage />
              </DefaultLayout>
            </Suspense>
          }
        />
        <Route
          path={routes.updateDB}
          element={
            <Suspense
              fallback={
                <DefaultLayout>
                  <AppPreloader />
                </DefaultLayout>
              }
            >
              <DefaultLayout>
                <UpdateDBPage />
              </DefaultLayout>
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
