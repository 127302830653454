import { Spin } from 'antd'

const AppPreloader = () => (
  <div
    style={{
      width: '100%',
      height: 'calc(100vh - 170px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Spin />
  </div>
)

export default AppPreloader
