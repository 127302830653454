import { AxiosError, AxiosResponse } from 'axios'
import { IPaginate, IUsersFilter, IUserShort } from 'models'
import { NavigateFunction } from 'react-router-dom'
import { call, debounce, put } from 'redux-saga/effects'
import { setUsers, usersFetchRequested } from 'store/reducers/usersReducer'
import api from 'utils/api'

function* fetchUsers(action: {
  type: string
  filter?: IUsersFilter
  pageNumber: number
  navigation: NavigateFunction
}) {
  const params: {
    city?: string
    ['user-name']?: string
    ['car-type-name']?: string
    ['is-owner']?: boolean
    ['page-size']: number
    ['page-number']: number
  } = {
    'page-size': 10,
    'page-number': action.pageNumber
  }

  if (action.filter?.name) {
    params['user-name'] = action.filter.name
  }
  if (action.filter?.city) {
    params.city = action.filter.city
  }
  if (action.filter?.carType) {
    params['car-type-name'] = action.filter.carType
  }
  if (action.filter?.isOwner) {
    params['is-owner'] = action.filter.isOwner
  }

  const response: AxiosResponse<IPaginate<IUserShort>> = yield call(() =>
    api
      .get('/users/data', {
        params
      })
      .catch((e: AxiosError) => {
        if ((e.toJSON() as { status: number }).status === 401) {
          action.navigation('/auth')
        }
      })
  )
  if (response) {
    yield put(setUsers(response.data))
  }
}

export default function* usersSaga() {
  yield debounce(300, usersFetchRequested.type, fetchUsers)
}
