import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPaginate, IProviderCardShort } from 'models'
import { RootState } from 'store'

type ProviderCardsStateType = IPaginate<IProviderCardShort> | null
const initialState = null as ProviderCardsStateType

export const providerCardsSlice = createSlice({
  name: 'provider-cards',
  initialState,
  reducers: {
    setProviderCards: (
      state,
      action: PayloadAction<IPaginate<IProviderCardShort>>
    ) => {
      state = action.payload
      return state
    }
  }
})

export const { setProviderCards } = providerCardsSlice.actions

export const providerCardsFetchRequested = {
  type: 'provider-cards/providerCardsFetchRequested'
}

export const selectProviderCards = (state: RootState) => state.providerCards

export default providerCardsSlice.reducer
