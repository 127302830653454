import { AxiosResponse } from 'axios'
import { INotification } from 'models'
import moment from 'moment'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  notificationFetchRequested,
  setNotification
} from 'store/reducers/notificationReducer'
import api from 'utils/api'

function* fetchNotification(action: { type: string; id: number }) {
  const response: AxiosResponse<INotification> = yield call(() =>
    api.get(`/notices/${action.id}`)
  )
  response.data.createdAt = moment(response.data.createdAt).format(
    'yyyy.MM.DD HH:mm'
  )
  yield put(setNotification(response.data))
}

export default function* notificationSaga() {
  yield takeEvery(notificationFetchRequested.type, fetchNotification)
}
