import { AxiosResponse } from 'axios'
import { ICarType, ICity, IFacility, IWheelSize } from 'models'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  citiesFetchRequested,
  setCities,
  transportTypesFetchRequested,
  setTransportTypes,
  cardTagsFetchRequested,
  setCardTags,
  setWheelSizes,
  wheelSizesFetchRequested,
  facilitiesFetchRequested,
  setFacilities
} from 'store/reducers/commonReducer'
import api from 'utils/api'
import { cardTagsMapper } from 'utils/mappers'
import { ICardTagResponse } from 'utils/utilTypes'

function* fetchCities() {
  const response: AxiosResponse<ICity[]> = yield call(() => api.get('/cities'))
  yield put(setCities(response.data))
}

function* fetchTransportTypes() {
  const response: AxiosResponse<ICarType[]> = yield call(() =>
    api.get('/car-types')
  )
  yield put(setTransportTypes(response.data))
}

function* fetchCardTags() {
  const response: AxiosResponse<ICardTagResponse[]> = yield call(() =>
    api.get('/tags')
  )

  yield put(setCardTags(cardTagsMapper(response.data)))
}

function* fetchWheelSizes() {
  const response: AxiosResponse<IWheelSize[]> = yield call(() =>
    api.get('/wheel-sizes')
  )
  yield put(setWheelSizes(response.data))
}

function* fetchFacilities() {
  const response: AxiosResponse<IFacility> = yield call(() =>
    api.get('/facilities?grouped-by=mapPointTypes')
  )
  yield put(setFacilities(response.data))
}

export default function* commonSaga() {
  yield takeEvery(citiesFetchRequested.type, fetchCities)
  yield takeEvery(transportTypesFetchRequested.type, fetchTransportTypes)
  yield takeEvery(cardTagsFetchRequested.type, fetchCardTags)
  yield takeEvery(wheelSizesFetchRequested.type, fetchWheelSizes)
  yield takeEvery(facilitiesFetchRequested.type, fetchFacilities)
}
