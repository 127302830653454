import { combineReducers } from '@reduxjs/toolkit'
import commonReducer from './commonReducer'
import notificationReducer from './notificationReducer'
import notificationsReducer from './notificationsReducer'
import profileReducer from './profileReducer'
import providerCardReducer from './providerCardReducer'
import providerCardsReducer from './providerCardsReducer'
import userReducer from './userReducer'
import usersReducer from './usersReducer'

export default combineReducers({
  common: commonReducer,
  users: usersReducer,
  user: userReducer,
  providerCards: providerCardsReducer,
  providerCard: providerCardReducer,
  profile: profileReducer,
  notifications: notificationsReducer,
  notification: notificationReducer
})
