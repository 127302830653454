import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ICardTag,
  ICity,
  IServiceType,
  ICarType,
  IWheelSize,
  IFacility
} from 'models'
import { RootState } from 'store'

type CommonState = {
  cities: ICity[]
  transportTypes: ICarType[]
  serviceTypes: IServiceType[]
  cardTags: ICardTag[]
  wheelSizes: IWheelSize[]
  facilities: IFacility
}

const initialState: CommonState = {
  cities: [],
  transportTypes: [],
  serviceTypes: [],
  cardTags: [],
  wheelSizes: [],
  facilities: {}
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCities: (state, action: PayloadAction<ICity[]>) => {
      state.cities = action.payload
    },
    setTransportTypes: (state, action: PayloadAction<ICarType[]>) => {
      state.transportTypes = action.payload
    },
    setServiceTypes: (state, action: PayloadAction<IServiceType[]>) => {
      state.serviceTypes = action.payload
    },
    setCardTags: (state, action: PayloadAction<ICardTag[]>) => {
      state.cardTags = action.payload
    },
    setWheelSizes: (state, action: PayloadAction<IWheelSize[]>) => {
      state.wheelSizes = action.payload
    },
    setFacilities: (state, action: PayloadAction<IFacility>) => {
      state.facilities = action.payload
    }
  }
})

export const {
  setCities,
  setTransportTypes,
  setServiceTypes,
  setCardTags,
  setWheelSizes,
  setFacilities
} = commonSlice.actions

export const moderationCardsCountFetchRequested = {
  type: 'common/moderationCardsCountFetchRequested'
}
export const citiesFetchRequested = {
  type: 'common/citiesFetchRequested'
}
export const transportTypesFetchRequested = {
  type: 'common/transportTypesFetchRequested'
}
export const serviceTypesFetchRequested = {
  type: 'common/serviceTypesFetchRequested'
}
export const servicesFetchRequested = {
  type: 'common/servicesFetchRequested'
}
export const cardTagsFetchRequested = {
  type: 'common/cardTagsFetchRequested'
}
export const wheelSizesFetchRequested = {
  type: 'common/cardTagsFetchRequested'
}
export const facilitiesFetchRequested = {
  type: 'common/cardTagsFetchRequested'
}

export const selectCities = (state: RootState) => state.common.cities
export const selectTransportTypes = (state: RootState) =>
  state.common.transportTypes
export const selectServiceTypes = (state: RootState) =>
  state.common.serviceTypes
export const selectCardTags = (state: RootState) => state.common.cardTags
export const selectWheelSizes = (state: RootState) => state.common.wheelSizes
export const selectFacilities = (state: RootState) => state.common.facilities

export default commonSlice.reducer
