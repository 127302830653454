import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPaginate, IUserShort } from 'models'
import { RootState } from 'store'

type UsersStateType = IPaginate<IUserShort> | null

const initialState = null as UsersStateType

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<IPaginate<IUserShort>>) => {
      state = action.payload
      return state
    }
  }
})

export const { setUsers } = usersSlice.actions

export const usersFetchRequested = {
  type: 'users/usersFetchRequested'
}

export const selectUsers = (state: RootState) => state.users

export default usersSlice.reducer
