import { all } from 'redux-saga/effects'
import commonSaga from './commonSaga'
import notificationSaga from './notificationSaga'
import notificationsSaga from './notificationsSaga'
import profileSaga from './profileSaga'
import providerCardSaga from './providerCardSaga'
import providerCardsSaga from './providerCardsSaga'
import userSaga from './userSaga'
import usersSaga from './usersSaga'

export default function* rootSaga() {
  yield all([
    commonSaga(),
    usersSaga(),
    userSaga(),
    providerCardsSaga(),
    providerCardSaga(),
    profileSaga(),
    notificationsSaga(),
    notificationSaga()
  ])
}
