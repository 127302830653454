import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserFull } from 'models'
import { RootState } from 'store'

type ProfileStateType = IUserFull | null

const initialState = null as ProfileStateType

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IUserFull>) => {
      state = { ...action.payload }
      return state
    }
  }
})

export const { setProfile } = profileSlice.actions

export const profileFetchRequested = {
  type: 'profile/profileFetchRequested'
}

export const selectProfile = (state: RootState) => state.profile

export default profileSlice.reducer
