import { AxiosError, AxiosResponse } from 'axios'
import { IUserFull } from 'models'
import { NavigateFunction } from 'react-router-dom'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  profileFetchRequested,
  setProfile
} from 'store/reducers/profileReducer'
import api from 'utils/api'

function* fetchProfile(action: { type: string; navigation: NavigateFunction }) {
  const response: AxiosResponse<IUserFull> = yield call(() =>
    api.get('/users/my-account').catch((e: AxiosError) => {
      if ((e.toJSON() as { status: number }).status === 401) {
        action.navigation('/auth')
      }
    })
  )
  if (response) {
    yield put(setProfile(response.data))
  }
}

export default function* profileSaga() {
  yield takeEvery(profileFetchRequested.type, fetchProfile)
}
