import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserFull } from 'models'
import { RootState } from 'store'

type UserStateType = IUserFull | null

const initialState = null as UserStateType

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserFull>) => {
      state = { ...action.payload }
      return state
    }
  }
})

export const { setUser } = userSlice.actions

export const userFetchRequested = {
  type: 'user/userFetchRequested'
}

export const selectUser = (state: RootState) => state.user

export default userSlice.reducer
