import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotification } from 'models'
import { RootState } from 'store'

type NotificationStateType = INotification | null

const initialState = null as NotificationStateType

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState as INotification | null,
  reducers: {
    setNotification: (state, action: PayloadAction<INotification>) => {
      state = action.payload
      return state
    }
  }
})

export const { setNotification } = notificationSlice.actions

export const notificationFetchRequested = {
  type: 'notification/notificationFetchRequested'
}

export const selectNotification = (state: RootState) => state.notification

export default notificationSlice.reducer
