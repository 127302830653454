import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProviderCardFull, IProviderCardShort, IUserFull } from 'models'
import { RootState } from 'store'

type ProviderCardStateType = IProviderCardFull | null

const initialState = null as ProviderCardStateType

export const providerCardSlice = createSlice({
  name: 'provider-card',
  initialState,
  reducers: {
    setProviderCard: (state, action: PayloadAction<IProviderCardFull>) => {
      state = { ...action.payload }
      return state
    },
    clearProviderCard: () => null
  }
})

export const { setProviderCard, clearProviderCard } = providerCardSlice.actions

export const providerCardFetchRequested = {
  type: 'provider-card/providerCardFetchRequested'
}

export const selectProviderCard = (state: RootState) => state.providerCard

export default providerCardSlice.reducer
